import React, { Component } from 'react';
import Header from './header';
import Introduction from './introduction';
import Journey from './journey';
import TrackingBanner from '../TrackingBanner/tracking-banner';

class LandingSection extends Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.state = {
      dataIndex: 0,
      mounted: true
    };
  }



  componentDidMount() {
    this.interval = setInterval(() => this.cycleLandingContent(), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    if (this.timeout) clearTimeout(this.timeout);
  }

  cycleLandingContent () {
    this.setState(prevState => ({ dataIndex: prevState.dataIndex+1 }));

    if (this.state.dataIndex >= 2) {
      clearInterval(this.interval);
      this.timeout = setTimeout(() => this.setState({mounted: false}), 1000);
    }
  }

  render() {
    const { entre, main, dessert, nick, florence, paris, munich } = this.props;
    const { dataIndex, mounted } = this.state;

    return (
      <>
        <Header entre={entre} main={main} dessert={dessert} mounted={mounted} dataIndex={dataIndex} />
        <Introduction nick={nick} />
        <Journey florence={florence} paris={paris} munich={munich} />
        <TrackingBanner />
      </>
    );
  }
}

export default LandingSection;
