import React from 'react';
import styles from './hero-text.module.scss';

export default function HeroText({ children, active }) {
  return(
    <span className={`${styles.container} ${active ? styles.active : styles.inactive}`}>
      {children}
    </span>
  );
}
