import React, { useState } from 'react';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import { Waypoint } from 'react-waypoint';
import { useIntl } from 'react-intl';
import Img from 'gatsby-image';
import styles from './landing.module.scss';
import Button from '../Button/button';

export default function Journey({ florence, paris, munich }) {
  const [imagesMounted, setImagesMounted] = useState(false);
  const intl = useIntl();
  return(
    <section className={styles.contentSection}>
      <div className={styles.contentWrapper}>
        <h2 className={styles.sectionHeadline}>{intl.formatMessage({ id: "journeySectionHeadline" })}</h2>
        <Waypoint onEnter={() => {if (!imagesMounted) setImagesMounted(true);}} />

        {
          imagesMounted ?
            <>
              <div className={styles.journeyFlorence}>
                <div>
                  <Img fluid={florence} alt="florence" loading={'lazy'} />
                </div>
                <div>
                  <p className={styles.sectionText}>
                    {intl.formatMessage({ id: "journeyFlorenceOne" })}
                  </p>
                  <p className={styles.sectionText}>
                    {intl.formatMessage({ id: "journeyFlorenceTwo" })}
                  </p>
                </div>
              </div>

              <div className={styles.journeyParis}>
                <div>
                  <p className={styles.sectionText}>
                    {intl.formatMessage({ id: "journeyParisOne" })}
                  </p>
                  <p className={styles.sectionText}>
                    {intl.formatMessage({ id: "journeyParisTwo" })}
                  </p>
                </div>
                <div>
                  <Img fluid={paris} alt="paris" loading={'lazy'} />
                </div>
              </div>

              <div className={styles.journeyMunich}>
                <div className={styles.munichImage}>
                  <Img fluid={munich} alt="munich" loading={'lazy'} />
                </div>
                <p className={`${styles.sectionText} ${styles.munichTextA}`}>
                  {intl.formatMessage({ id: "journeyMunichOne" })}
                </p>
                <p className={`${styles.sectionText} ${styles.munichTextB}`}>
                  {intl.formatMessage({ id: "journeyMunichTwo" })}
                </p>
                <p className={`${styles.sectionText} ${styles.munichTextC}`}>
                  {intl.formatMessage({ id: "journeyMunichThree" })}
                </p>
              </div>
            </>
            :
            null
        }

        <h2 className={styles.sectionHeadline}>{intl.formatMessage({ id: "contactSectionHeadline" })}</h2>
        <p className={styles.sectionText}>{intl.formatMessage({ id: "contactSectionText" })}</p>

        <div className={styles.cta}>
          <Link to={'/get-quote/address'}>
            <Button handleClick={() => {}} disabled={false} elevated>
              {intl.formatMessage({ id: "cta" })}
            </Button>
          </Link>
        </div>
        <div className={styles.spacer} />
      </div>
    </section>
  );
}
