import React from 'react';
import Img from 'gatsby-image';
import styles from './plate.module.scss';

export default function Plate({ sources, alt, active }) {
  return(
    <div className={`${styles.plateContainer} ${active ? styles.active : ''}`}>
      <Img fluid={sources} alt={alt} />
    </div>
  );
}
