import React, { useState } from 'react';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import { Waypoint } from 'react-waypoint';
import { useIntl } from 'react-intl';
import Img from 'gatsby-image';
import styles from './landing.module.scss';
import Card from '../Card/card';
import Button from '../Button/button';

export default function Introduction({ nick }) {
  const [imageMounted, setImageMounted] = useState(false);
  const intl = useIntl();
  return(
    <section className={styles.contentSection}>
      <div className={styles.contentWrapper}>
        <h2 className={styles.sectionHeadline}>Hi!</h2>
        <Waypoint onEnter={() => {if (!imageMounted) setImageMounted(true);}} />
        {imageMounted ?
          <div className={styles.introductionContainer}>
            <p className={`${styles.sectionText} ${styles.introA}`}>{intl.formatMessage({ id: "introductionOne" })}</p>
            <Img className={styles.introImage} fluid={nick} alt="nick braat" />
            <p className={`${styles.sectionText} ${styles.introB}`}>{intl.formatMessage({ id: "introductionTwo" })}</p>
            <p className={`${styles.sectionText} ${styles.introC}`}>{intl.formatMessage({ id: "introductionThree" })}</p>
        </div> : null}

        <div className={styles.cardContainer}>
          <Card
            imgSrc="/images/menu.svg"
            imgAlt="menu"
            text={intl.formatMessage({ id: "cardOne" })}
          />

          <Card
            imgSrc="/images/shopping-cart.svg"
            imgAlt="shopping cart"
            text={intl.formatMessage({ id: "cardTwo" })}
          />

          <Card
            imgSrc="/images/prepare.svg"
            imgAlt="chopping board"
            text={intl.formatMessage({ id: "cardThree" })}
          />

          <Card
            imgSrc="/images/washing-up.svg"
            imgAlt="clean dishes"
            text={intl.formatMessage({ id: "cardFour" })}
          />
        </div>

        <div className={styles.cta}>
          <Link to={'/get-quote/address'}>
            <Button handleClick={() => {}} disabled={false} elevated>
              {intl.formatMessage({ id: "cta" })}
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
}
