import React from 'react';
import { Link as L } from 'gatsby';
import { LocalizedLink as Link, useLocalization } from 'gatsby-theme-i18n';
import { useIntl } from 'react-intl';
import styles from './landing.module.scss';
import Plate from '../Plate/plate';
import HeroText from '../HeroText/hero-text';
import Button from '../Button/button';
import GermanyFlag from '../Icons/germany-flag';
import USAFlag from '../Icons/usa-flag';


export default function Header({ entre, main, dessert, mounted, dataIndex }) {
  const { locale } = useLocalization();
  const intl = useIntl();
  return(
    <header className={styles.header}>
      <img className={styles.logo} src={'/logos/logo-light-line-1.svg'} alt="logo" />
      <div className={styles.lang}>
        {locale === 'de' ? <L to={'/en'}><USAFlag /></L> : <L to={'/'}><GermanyFlag /></L>}
      </div>
      <div className={styles.waveArt} />

      <h1 className={styles.hiddenHero}>Nick Braat, Michelin star trained chef</h1>

      <Plate sources={entre} alt="entre" active={dataIndex === 0} />
      <Plate sources={main} alt="main" active={dataIndex === 1} />
      <Plate sources={dessert} alt="dessert" active={dataIndex === 2} />

      <div className={styles.ctaContainer}>
        <div className={styles.heroText}>
          {mounted ? <HeroText active={dataIndex === 0}>{intl.formatMessage({ id: "landingCycleOne" })}</HeroText> : null}
          {mounted ? <HeroText active={dataIndex === 1}>{intl.formatMessage({ id: "landingCycleTwo" })}</HeroText> : null}
          <HeroText active={dataIndex === 2}>{intl.formatMessage({ id: "landingCycleThree" })}</HeroText>
        </div>

        <div className={styles.cta}>
          <Link to={'/get-quote/address'}>
            <Button handleClick={() => {}} disabled={false} elevated>
              {intl.formatMessage({ id: "cta" })}
            </Button>
          </Link>
        </div>
      </div>
      <img className={styles.chevronDown} src={'/images/chevron-down.svg'} alt="chevron down" />
    </header>
  );
}
