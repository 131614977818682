import React from 'react';
import { GTAG_OPTIN_KEY } from "gatsby-plugin-google-gtag-optin";
import BannerContent from './banner-content';

export default class TrackingBanner extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      statistics: false,
      visible: true
    }
  }

  componentDidMount() {
    if (localStorage.getItem(GTAG_OPTIN_KEY)) this.setState({visible: false});
  }

  handleCheckboxChange = (val) => {
    this.setState(prevState => ({[val]: !prevState[val]}));
  }

  consent = () => {
    localStorage.setItem(GTAG_OPTIN_KEY, true);
    if (typeof window.loadGtag === "function") {
      window.loadGtag();
    }
  }

  handleButtonClick = (type) => {
    if (type === 'selection') {
      const { statistics } = this.state;
      if (statistics) this.consent();
    } else if (type === 'all') {
      this.consent();
    }

    this.setState({visible: false});
  }

  render() {
    const { statistics, visible } = this.state;

    if (visible)
    return(
      <BannerContent
        statistics={statistics}
        handleButtonClick={this.handleButtonClick}
        handleCheckboxChange={this.handleCheckboxChange}
      />
    );

    return null;
  }
}
