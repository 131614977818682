import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import config from '../../data/SiteConfig';
import SEO from '../components/SEO/SEO';
import MainLayout from '../components/MainLayout/main-layout';
import Landing from '../components/Landing/landing';

function HomePage({ data }) {
    // Set up the array of image data and `media` keys.
    // You can have as many entries as you'd like.
    const entre = [
        data.mobileEntre.childImageSharp.fluid,
        {
            ...data.desktopEntre.childImageSharp.fluid,
            media: `(min-width: 768px)`,
        }
    ];

    const main = [
        data.mobileMain.childImageSharp.fluid,
        {
            ...data.desktopMain.childImageSharp.fluid,
            media: `(min-width: 768px)`,
        }
    ];

    const dessert = [
        data.mobileDessert.childImageSharp.fluid,
        {
            ...data.desktopDessert.childImageSharp.fluid,
            media: `(min-width: 768px)`,
        }
    ];

    const nick = [
        data.mobileNick.childImageSharp.fluid,
        {
            ...data.desktopNick.childImageSharp.fluid,
            media: `(min-width: 768px)`,
        }
    ];

    const florence = [
        data.mobileFlorence.childImageSharp.fluid,
        {
            ...data.desktopFlorence.childImageSharp.fluid,
            media: `(min-width: 768px)`,
        }
    ];
    const paris = [
        data.mobileParis.childImageSharp.fluid,
        {
            ...data.desktopParis.childImageSharp.fluid,
            media: `(min-width: 768px)`,
        }
    ];
    const munich = [
        data.mobileMunich.childImageSharp.fluid,
        {
            ...data.desktopMunich.childImageSharp.fluid,
            media: `(min-width: 768px)`,
        }
    ];


    return (
      <MainLayout>
        <Helmet title={config.siteTitle} />
        <SEO />
        <Landing
          entre={entre}
          main={main}
          dessert={dessert}
          nick={nick}
          florence={florence}
          paris={paris}
          munich={munich}
        />
      </MainLayout>
    );
}

export default HomePage;

// mobile fragment
export const mobileImage = graphql`
  fragment mobileImage on File {
    childImageSharp {
      fluid(maxWidth: 500, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

// mobile fragment
export const desktopImage = graphql`
  fragment desktopImage on File {
    childImageSharp {
      fluid(maxWidth: 1000, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

/* eslint no-undef: "off" */
export const query = graphql`
query {
    mobileEntre: file(relativePath: { eq: "entre.png" }) {
      ...mobileImage
    }
    
    mobileMain: file(relativePath: { eq: "main.png" }) {
      ...mobileImage
    }
    
    mobileDessert: file(relativePath: { eq: "dessert.png" }) {
      ...mobileImage
    }

    mobileNick: file(relativePath: { eq: "nick-art-wall.png" }) {
      ...mobileImage
    }
    
    mobileFlorence: file(relativePath: { eq: "florence.png" }) {
      ...mobileImage
    }
    
    mobileParis: file(relativePath: { eq: "eifeltower.png" }) {
      ...mobileImage
    }
    
    mobileMunich: file(relativePath: { eq: "munich.png" }) {
      ...mobileImage
    }
    
    desktopEntre: file(relativePath: { eq: "entre-desktop.png" }) {
      ...desktopImage
    }
    
    desktopMain: file(relativePath: { eq: "main-desktop.png" }) {
      ...desktopImage
    }
    
    desktopDessert: file(relativePath: { eq: "dessert-desktop.png" }) {
      ...desktopImage
    }
    
    desktopNick: file(relativePath: { eq: "nick-art-wall-desktop.png" }) {
      ...desktopImage
    }
    
    desktopFlorence: file(relativePath: { eq: "florence-desktop.png" }) {
      ...desktopImage
    }
    
    desktopParis: file(relativePath: { eq: "eifeltower-desktop.png" }) {
      ...desktopImage
    }
    
    desktopMunich: file(relativePath: { eq: "munich-desktop.png" }) {
      ...desktopImage
    }
  }
`
