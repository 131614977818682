import React from 'react';
import { useIntl } from 'react-intl';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import styles from './tracking-banner.module.scss';

export default function BannerContent({ handleButtonClick, handleCheckboxChange, statistics }) {
  const intl = useIntl();
  return(
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div>
          {intl.formatMessage({ id: "trackingDisclaimer" })}
        </div>
        <div className={styles.buttons}>
          <button type="button" onClick={() => handleButtonClick('all')}>{intl.formatMessage({ id: "trackingButtonAllowAll" })}</button>
          <button type="button" onClick={() => handleButtonClick('necessary')}>{intl.formatMessage({ id: "trackingButtonOnlyNecessary" })}</button>
          <button type="button" onClick={() => handleButtonClick('selection')}>{intl.formatMessage({ id: "trackingButtonAllowSelection" })}</button>
        </div>
        <div className={styles.selection}>
          <div className={styles.checkboxes}>
            <input type="checkbox" id="necessary" value="necessary" disabled checked />
            <label htmlFor="necessary" style={{color: '#acacac'}}>{intl.formatMessage({ id: "trackingCheckboxNecessary" })}</label>
            <input type="checkbox" id="statistics" value="statistics" checked={statistics} onChange={(e) => handleCheckboxChange(e.target.value)} />
            <label htmlFor="statistics">{intl.formatMessage({ id: "trackingCheckboxStatistics" })}</label>
          </div>
          <div className={styles.details}>
            <Link to={'/privacy'}>{intl.formatMessage({ id: "trackingCheckboxDetails" })}</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
