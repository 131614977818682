import React from 'react';
import styles from './card.module.scss';

export default function Card({imgSrc, imgAlt, text}) {
  return(
    <div className={styles.card}>
      <img className={styles.icon} src={imgSrc} alt={imgAlt} />
      <div className={styles.text}>
        {text}
      </div>
    </div>
  );
}
